export enum Path {

  Admin = 'admin',
  ChangePassword = 'admin/change-password',

  //Partner Lab Management
  Lab = 'admin/test-labs',
  CreateLab = 'admin/new-test-partner',
  EditLab = 'admin/test-partner',

  LabCenter = 'admin/test-centers',
  CreateLabCenter = 'admin/new-test-center',
  EditLabCenter = 'admin/test-center',

  TestType = 'admin/test-types',
  CreateTestType = 'admin/new-test-type',
  EditTestType = 'admin/test-type',

  //Remote Selling
  CreateFacility='admin/remote-selling/create-new-facility',
  ViewFacilities = 'admin/remote-selling/view-facilities',
  FacilityRequests ='admin/remote-selling/view-facility-requests',
  CreateFacilityRequest = 'admin/remote-selling/view-facility-requests/new-facility-request',
  ViewTestKitVials = 'admin/remote-selling/view-facility-requests',
  //Auth
  Login = '',
  TestKits = 'admin/remote-selling/test-kits',

  ResetPassword = 'admin/reset-password',
  Partners = 'admin/integrations/partners',
  EmployerGroup = 'admin/integrations/employer-group',

  Search = 'admin/search',

}
