import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Path } from '../core/enums/path.enum';

@Injectable({
  providedIn: 'root'
})
 export class AuthGuard implements CanActivate {
    constructor(private routes: Router) { }
    canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): boolean {
      if (localStorage.getItem('token') != null) {
        return true;
      }
      else {
        this.routes.navigate([Path.Login]);
        return false;
      }
    }

}
