// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

let stripeTestPubKey: string = 'pk_test_51K2eU9DZKxMYHpotQwMVgBrPOstPFG3GxG7Elr3MzQg4P2fh5oIuekExGwqweS8Lx8SjIntCSfsc5Gc1HMQqZg9g00D4QmJBoL';
let stripeTestSecretKey: string = 'sk_test_51K2eU9DZKxMYHpotdADnE2iQL5Mk0xjbtdAulcxjZaoaNsWvLfLz2wu1tenp5f8Tq218n2AfwXvsm0WdO6FV4atd00JWpslu8j';
let stripeLivePubKey: string = 'pk_live_51K2eU9DZKxMYHpot7Jrq4IwfNkB5Kj6VkXWSeqc5v099ihteOew0WO6RRvmiYYDj0FKAH9pRvayFgSYhqVQsKetq00RizBXjXh';
let stripeLiveSecretKey: string = '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';
let env: boolean = false;

export const environment = {
  production: env,
  apiBaseUrl: 'https://api.malady.co',            // API_Live
  // apiBaseUrl: 'https://api-test.malady.co',       // API_Test
  // apiBaseUrl: 'http://localhost:85',              // local machine API

  redirectSignIn: 'http://localhost:4200/auth/login',
  redirectSignOut: 'http://localhost:4200/login',
  GOOGLE_PLACE_API_KEY: 'google place api key',
  GOOGLE_PROXY_BASE_URL: 'http://localhost:4200',
  routeDashboard: 'https://sandbox-booking.malady.co/patient'
};

export const stripe = {
  stripe_key: env ? stripeLivePubKey : stripeTestPubKey,
  stripe_secret: env ? stripeLiveSecretKey : stripeTestSecretKey,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
